<template>
  <main>
    <h1>Allgemeine Geschäftsbedingungen</h1>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod illo aut
      illum eum voluptatem omnis modi obcaecati delectus, explicabo facilis nisi
      deleniti harum laudantium accusantium similique, exercitationem unde fugit
      hic!
    </p>
    <p>
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, magnam
      ratione. Iure numquam, hic ab illo dolor obcaecati. Maxime magni odio
      culpa facere iste accusamus laboriosam pariatur eaque ab rem.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur impedit
      magnam quae quibusdam voluptatibus voluptatum, molestias, fuga, ullam
      voluptas tempore officiis harum quidem eveniet. Soluta aliquam delectus
      aliquid maiores reprehenderit!
    </p>
  </main>
</template>
<script>
export default {
  name: "AGBSection",
};
</script>

<style scoped>
main {
  margin-top: 7rem;
  color: #fff;
  padding: 3rem;
  text-align: center;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-radius: 13px;
  background: linear-gradient(145deg, #a0e8da32, #0e393028);
  box-shadow: 5px 5px 10px var(--weboda-color), -5px -5px 10px white;
}
h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: "Agdasima", sans-serif;
}
h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: "Agdasima", sans-serif;
}
p {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  font-family: "Agdasima", sans-serif;
}
</style>
```
