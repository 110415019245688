<template>
  <div class="chatbox-container">
    <div class="container" v-if="showChatBox">
      <h1>
        AI Chat
        <div class="toggleButton roundclose" @click="toggleChatBox">
          <span class="chatbot-text">Close</span>
          <svg
            width="30"
            height="30"
            viewBox="0 0 2048 2048"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="currentColor"
              d="M768 1024H640V896h128zm512 0h-128V896h128zm512-128v256h-128v320q0 40-15 75t-41 61t-61 41t-75 15h-264l-440 376v-376H448q-40 0-75-15t-61-41t-41-61t-15-75v-320H128V896h128V704q0-40 15-75t41-61t61-41t75-15h448V303q-29-17-46-47t-18-64q0-27 10-50t27-40t41-28t50-10q27 0 50 10t40 27t28 41t10 50q0 34-17 64t-47 47v209h448q40 0 75 15t61 41t41 61t15 75v192zm-256-192q0-26-19-45t-45-19H448q-26 0-45 19t-19 45v768q0 26 19 45t45 19h448v226l264-226h312q26 0 45-19t19-45zm-851 462q55 55 126 84t149 30q78 0 149-29t126-85l90 91q-73 73-167 112t-198 39q-103 0-197-39t-168-112z"
            />
          </svg>
        </div>
      </h1>
      <div class="messageBox mt-8">
        <!-- Display chat messages -->
        <section v-for="(message, index) in messages" :key="index">
          <div
            :class="
              message.from === 'user' ? 'messageFromUser' : 'messageFromChatGpt'
            "
          >
            <div
              :class="
                message.from === 'user'
                  ? 'userMessageWrapper'
                  : 'chatGptMessageWrapper'
              "
            >
              <div
                :class="
                  message.from === 'user'
                    ? 'userMessageContent'
                    : 'chatGptMessageContent'
                "
              >
                <span v-if="message.from !== 'user'" class="messageSender"
                  >amox:</span
                >
                {{ message.data }}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="inputContainer">
        <input
          v-model="currentMessage"
          type="text"
          class="messageInput"
          placeholder="Ask me anything..."
          @keyup.enter="sendMessage"
        />
        <button @click="sendMessage" class="askButton">Ask</button>
      </div>
    </div>
    <div class="toggleButton round" @click="toggleChatBox" v-if="!showChatBox">
      <span class="chatbot-text">AI Chat</span>
      <svg
        width="40"
        height="40"
        viewBox="0 0 2048 2048"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M768 1024H640V896h128zm512 0h-128V896h128zm512-128v256h-128v320q0 40-15 75t-41 61t-61 41t-75 15h-264l-440 376v-376H448q-40 0-75-15t-61-41t-41-61t-15-75v-320H128V896h128V704q0-40 15-75t41-61t61-41t75-15h448V303q-29-17-46-47t-18-64q0-27 10-50t27-40t41-28t50-10q27 0 50 10t40 27t28 41t10 50q0 34-17 64t-47 47v209h448q40 0 75 15t61 41t41 61t15 75v192zm-256-192q0-26-19-45t-45-19H448q-26 0-45 19t-19 45v768q0 26 19 45t45 19h448v226l264-226h312q26 0 45-19t19-45zm-851 462q55 55 126 84t149 30q78 0 149-29t126-85l90 91q-73 73-167 112t-198 39q-103 0-197-39t-168-112z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ChatBox",
  data() {
    return {
      currentMessage: "",
      messages: [],
      showChatBox: false,
    };
  },
  methods: {
    toggleChatBox() {
      this.showChatBox = !this.showChatBox;
    },
    async sendMessage() {
      const message = this.currentMessage.trim();
      if (message === "") return;

      this.messages.push({
        from: "user",
        data: message,
      });

      // After receiving the response from the server
      /*    try { */
      const response = await axios.post("http://localhost:3005/query", {
        text: message,
      });

      console.log("Response from server:", response.data);

      // Check if the response contains web search results
      if (response.data.action === "web" && response.data.result) {
        /*  if (response.data.result) { */
        // Handle web search response
        const webSearchResult = response.data.result;
        console.log("Web Search Results:", webSearchResult);
        console.log("Web Search ", response.data.result);
        this.messages.push({
          from: "amox",
          data: webSearchResult,
        });
      } else {
        // Handle regular chat messages
        const botResponse = response.data.answer.response;
        this.messages.push({
          from: "amox",
          data: botResponse,
        });
        console.log("Bot Response:", botResponse);
      }
      /* } catch (error) {
        console.error("Error sending message:", error);
        this.messages.push({
          from: "amox",
          data: "Sorry, I couldn't process your request.",
        });
      } */
      this.currentMessage = "";
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

.chatbox-container {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1000;
}

.container {
  width: 360px;
  height: 600px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
}

h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #222;
  padding: 16px;
  margin: 0;
  background-color: #f7f7f7;
  border-bottom: 1px solid #e7e7e7;
}

.messageBox {
  padding: 16px;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.messageFromUser,
.messageFromChatGpt {
  display: flex;
}

.messageBox {
  max-height: 400px;
  overflow-y: auto;
  padding: 0 16px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  flex-grow: 1;
}

.messageFromUser,
.messageFromChatGpt {
  display: flex;
  margin-bottom: 8px;
}

.userMessageWrapper,
.chatGptMessageWrapper {
  display: flex;
  flex-direction: column;
}

.userMessageWrapper {
  align-self: flex-end;
}

.chatGptMessageWrapper {
  align-self: flex-start;
}

.userMessageContent,
.chatGptMessageContent {
  max-width: 60%;
  padding: 8px 12px;
  border-radius: 18px;
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 1.4;
}

.userMessageContent {
  background-color: #18f24bab;
  color: white;
  border-top-left-radius: 0;
}

.chatGptMessageContent {
  background-color: #ededed;
  color: #222;
  border-top-right-radius: 0;
}

.userMessageTimestamp,
.chatGptMessageTimestamp {
  font-size: 10px;
  color: #999;
  margin-top: 2px;
}

.userMessageTimestamp {
  align-self: flex-end;
}

.chatGptMessageTimestamp {
  align-self: flex-start;
}

.inputContainer {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #f0f0f0;
}

.messageInput {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 12px;
  font-size: 16px;
  background-color: white;
  border-radius: 24px;
  margin-right: 8px;
}

.askButton {
  background-color: #3ccdbc;
  color: white;
  border: none;
  outline: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: rgba(164, 253, 247, 0.518) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.toggleButton {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 8px;
}

.toggleButton svg {
  font-size: 24px;
  color: #3ccdbc;
  transition: transform 0.3s ease-in-out;
}

.toggleButton svg.flipped {
  transform: rotate(180deg);
}

.toggleButton.round {
  box-sizing: border-box;
  /*   position: absolute;
  top: -705px;
  right: -3px; */
  background-color: #3ccdbc;
  color: black;
  border: none;
  outline: none;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 15px 15px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

/* @media screen and (max-width: 1500px) {
  .toggleButton.round {
    position: absolute;
    top: -790px;
    right: -13px;
  }
} */
.toggleButton.roundclose {
  background-color: #3ccdbc;
  background: transparent;
  color: #3ccdbc;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px 10px;
  border-radius: 50px;
  box-shadow: rgba(164, 253, 247, 0.518) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.toggleButton.round i {
  margin-right: 8px;
}
.toggleButton.close {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 24px;
  cursor: pointer;
}

.chatbot-text {
  margin-right: 8px;
}

@media (max-width: 480px) {
  .container {
    width: 100%;
    max-width: none;
    border-radius: 0;
  }
}
</style>
