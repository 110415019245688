<template>
  <DatenSchutz />
</template>

<script>
import DatenSchutz from "@/components/DatenSchutz.vue";
export default {
  components: {
    DatenSchutz,
  },
};
</script>
