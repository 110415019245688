<template>
  <AGBSection />
</template>

<script>
import AGBSection from "@/components/AGBSection.vue";
export default {
  components: {
    AGBSection,
  },
};
</script>
