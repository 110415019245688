<template>
  <footer class="footer-section">
    <div class="footer-content">
      <div>
        <i class="fab fa-facebook"></i>
        <i class="fab fa-twitter"></i>
        <i class="fab fa-instagram"></i>
        <router-link class="links" to="/agb">AGB</router-link>
        <router-link class="links" to="/datenschutz">Datenschutz</router-link>
        <router-link class="links" to="/impressum">Impressum</router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
  // Weitere Optionen und Methoden hier
};
</script>

<style scoped>
.footer-content i {
  cursor: pointer;
  margin: 0 16px 0 0;
  color: rgba(78, 33, 33, 0.75);
  transition: all 0.3s;
}
.footer-content i:hover {
  color: var(--weboda-color);
}

.footer-section {
  font-family: "Plus Jakarta Sans", sans-serif;
  position: relative;
  left: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 2rem;
  color: #fff;
  padding: 3rem;
  text-align: center;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-radius: 13px;
  background: linear-gradient(145deg, #a0e8da32, #0e393028);
  box-shadow: 5px 5px 10px var(--dark-green),
    -5px -5px 10px var(--primary-green);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.legal-links {
  margin-top: 10px;
}

.links {
  margin-right: 10px;
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
}

.links:hover {
  text-decoration: underline;
  color: var(--primary-green);
}

@media (max-width: 768px) {
  .links {
    font-size: 1rem;
  }
}
</style>
