<template>
  <main>
    <h1>Datenschutzinformationen</h1>
    <h2>
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sed consequuntur
      eum doloribus? Fuga eligendi, vero non qui eveniet sapiente, cumque
      possimus facere harum sequi iste velit praesentium voluptatem debitis
      voluptate.
    </h2>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente magnam,
      molestias accusamus aliquam laudantium ratione odio fugiat, commodi, saepe
      tempora voluptatum eligendi quam vero assumenda rerum omnis dolor at aut!
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis iusto ab,
      ullam repellat eos eligendi autem aliquam nostrum adipisci quaerat est
      corrupti, aperiam iste tempora, quod laborum dolore nulla? Vitae.
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis iusto ab,
      ullam repellat eos eligendi autem aliquam nostrum adipisci quaerat est
      corrupti, aperiam iste tempora, quod laborum dolore nulla? Vitae.
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis iusto ab,
      ullam repellat eos eligendi autem aliquam nostrum adipisci quaerat est
      corrupti, aperiam iste tempora, quod laborum dolore nulla? Vitae.
    </p>
  </main>
</template>
<script>
export default {
  name: "AGBSection",
};
</script>

<style scoped>
main {
  margin-top: 7rem;
  color: #fff;
  padding: 3rem;
  text-align: center;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-radius: 13px;
  background: linear-gradient(145deg, #a0e8da32, #0e393028);
  box-shadow: 5px 5px 10px var(--weboda-color), -5px -5px 10px white;
}
h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: "Agdasima", sans-serif;
}
h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: "Agdasima", sans-serif;
}
p {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  font-family: "Agdasima", sans-serif;
}
</style>
```
