<template>
  <div>
    <div>
      <LayoutHeader />
      <router-view />
    </div>
    <div class="chatbot">
      <!--      <ChatBox /> -->
    </div>
    <div>
      <FooterSection />
    </div>
  </div>
</template>

<script>
import LayoutHeader from "./components/LayoutHeader.vue";
/* import ChatBox from "./components/ChatBox.vue"; */
import FooterSection from "./components/FooterSection.vue";
import Lenis from "@studio-freight/lenis";

export default {
  components: {
    LayoutHeader,
    /*   ChatBox, */
    FooterSection,
  },
  setup() {
    const lenis = new Lenis();

    lenis.on("scroll", (e) => {
      console.log(e);
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;400&family=Staatliches&display=swap");

/* * {
  font-family: "Agdasima", sans-serif;
} */
:root {
  --box-shadow-btn: rgba(23, 23, 23, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

  --weboda-color: #3ccdbc;
  --primary: #ffffff;
  --primary-dark: #9c9c9c;
  --secondary: #f39c12;
}

/* html {
  overflow-x: hidden;
} */

body {
  max-width: 100vw;
  overflow-x: hidden;
  overscroll-behavior: none;
  transition: 0.3s ease-out;
  background-color: black;
}
body::-webkit-scrollbar {
  display: none;
}

.inside {
  margin: 10px;
}

/* @media only screen and (min-width: 1140px) {
  .inside {
    width: 960px;
    margin: 0 auto;
  }
} */
</style>
