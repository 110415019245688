<template>
  <img
    src="../assets/weboda-logo-original.png"
    alt="logo-weboda-digital-house"
    class="header__logo"
    @click="goToHome"
  />
  <header class="header">
    <section class="header__section">
      <!--       <SaleLabel /> -->
      <label class="hamburger" for="nav-toggle">
        <input type="checkbox" id="nav-toggle" v-model="isNavOpen" />
        <svg viewBox="0 0 32 32">
          <path
            class="line line-top-bottom"
            d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
          ></path>
          <path class="line" d="M7 16 27 16"></path>
        </svg>
      </label>

      <!--   <nav role="custom-dropdown" class="header__nav" v-show="isNavOpen">
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/about">About</router-link></li>
          <li><router-link to="/ki-auto">KI/Auto</router-link></li>
          <li><router-link to="/mobile-app">App</router-link></li>
          <li><router-link to="/web-design">Design</router-link></li>
          <li><router-link to="/contact">Contact</router-link></li>
        </ul>
      </nav> -->

      <div class="header__info-wrapper">
        <nav role="custom-dropdown" class="header__nav" v-show="isNavOpen">
          <router-link class="header__nav-item" to="/"
            >Home<span></span
          ></router-link>
          <router-link class="header__nav-item" to="/about"
            >About<span></span
          ></router-link>
          <router-link class="header__nav-item" to="/ki-auto"
            >KI/Auto<span></span
          ></router-link>
          <router-link class="header__nav-item" to="/mobile-app"
            >App<span></span
          ></router-link>
          <router-link class="header__nav-item" to="/web-design"
            >Design<span></span
          ></router-link>
          <router-link class="header__nav-item" to="/contact"
            >Contact<span></span
          ></router-link>
        </nav>
      </div>
      <ChatBox />
    </section>
  </header>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import ChatBox from "@/components/ChatBox.vue";
/* import SaleLabel from "@/components/SaleLabel.vue"; */

export default {
  name: "LayoutHeader",
  components: {
    ChatBox,
    /*  SaleLabel, */
  },
  setup() {
    const router = useRouter();
    const isNavOpen = ref(false);

    const goToHome = () => {
      router.push("/");
    };

    const toggleNav = () => {
      isNavOpen.value = !isNavOpen.value;
    };

    return {
      goToHome,
      isNavOpen,
      toggleNav,
    };
  },
};
</script>

<style scoped>
p {
  background: linear-gradient(to right, #ccc, #565656, #142511);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
* {
  box-sizing: border-box;
  font-family: "Plus Jakarta Sans", sans-serif;
}
img {
  width: 140px;
  height: 80px;
}
.header__logo {
  position: absolute;
  height: 80px;
  background-color: transparent;
  text-decoration: none;
  display: inline;
  margin-left: 0.3rem;
  padding: 0px 20px;
  z-index: 10;
}
@media screen and (max-width: 460px) {
  img {
    width: 120px;
    height: 30px;
  }
  .header__logo {
    margin-left: 0.1rem;
    padding: 0px 10px;
  }
}

.header {
  height: 3rem;
  z-index: 10000000;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  /*  margin-bottom: 20px; */
  margin-top: 0%;
}
.header__info-wrapper {
  height: 3rem;
  display: flex;
  justify-content: flex-end;
  z-index: 1000000;
}

.header__section {
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
  z-index: 1000000;
}
.header__section img {
  position: relative;
  top: 0px;
  left: 0;
}
@media screen and (max-width: 708px) {
  .header__section {
    flex-wrap: wrap;
  }
}

.header__nav {
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 27rem;

  /*  box-shadow: inset 0 0 100px rgba(65, 208, 221, 0.798); */
  box-shadow: rgba(247, 246, 246, 0.17) 0px -23px 25px 0px inset,
    rgba(65, 208, 221, 0.386) 0px -36px 30px 0px inset,
    rgba(255, 255, 255, 0.18) 0px -79px 40px 0px inset,
    rgba(0, 0, 0, 0.127) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;

  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  z-index: 1000000;
}

.header__nav-item {
  font-weight: 400;
  font-size: 0.7rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  color: black;

  display: inline;
  padding: 10px 10px;
  border-bottom: var(--weboda-color) 1px solid;
  z-index: 100000000000;
  /*  text-decoration: underline; */
}

.header__nav-item:hover {
  color: white;
}
.header__nav-item span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--weboda-color);
  border-radius: 30px;
  z-index: -1;
  transform: scale(0);
  transition: 0.5s;
}
.header__nav-item:hover span {
  transform: scale(1);
}

.router-link-exact-active {
  font-size: 0.8rem;
  font-weight: 400;
  color: black;
  background-color: var(--weboda-color);
  border-radius: 50px;
  box-shadow: var(--box-shadow-btn);
  text-decoration: none;
}
.hamburger {
  margin-left: auto;
  cursor: pointer;
}

.hamburger input {
  display: none;
}

.hamburger svg {
  /* The size of the SVG defines the overall size */
  height: 3em;
  /* Define the transition for transforming the SVG */
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: var(--weboda-color);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  /* Define the transition for transforming the Stroke */
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

.hamburger input:checked + svg {
  transform: rotate(-45deg);
}

.hamburger input:checked + svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}
/* @media screen and (min-width: 931px) {
  .hamburger {
    display: none;
  }
} */
@media screen and (max-width: 930px) {
  .header__nav {
    margin: 0;
    background: white;
    flex-direction: column;
    width: 10rem;
    height: 13rem;
    align-items: center;
  }
  .hamburger input:checked .header__nav {
    display: none;
  }
  .hamburger {
    display: block;
  }
}
@media screen and (max-width: 435px) {
  .header__nav {
    width: 10rem;
    z-index: 100000000;
  }
  .header__nav-item {
    font-size: 0.5rem;
    z-index: 100000000000;
  }
}
</style>
