import { createRouter, createWebHistory } from "vue-router";

import AGBSectionView from "../views/AGBSectionView.vue";
import DatenSchutzView from "../views/DatenSchutzView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    /*   component: HomeView, */
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },

  {
    path: "/about",
    name: "about",
    /*   component: AboutView, */
    //route level code-splitting
    //this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },

  {
    path: "/web-design",
    name: "web-design",
    /*    component: WebDesignView, */
    //route level code-splitting
    //this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "web-design" */ "../views/WebDesignView.vue"),
  },
  {
    path: "/mobile-app",
    name: "mobile-app",
    /*    component: MobileAppView, */
    //route level code-splitting
    //this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "mobile-app" */ "../views/MobileAppView.vue"),
  },
  {
    path: "/e-commerce",
    name: "e-commerce",

    //route level code-splitting
    //this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "e-commerce" */ "../views/ECommerceView.vue"),
  },
  {
    path: "/ki-auto",
    name: "ki-auto",
    /*     component: WebAutoView, */
    //route level code-splitting
    //this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "ki-auto" */ "../views/WebAutoView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    /*    component: ContactView, */
    //route level code-splitting
    //this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
  },
  {
    path: "/impressum",
    name: "impressum",
    /*     component: ImpressumSectionView, */
    //route level code-splitting
    //this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "impressum" */ "../views/ImpressumSectionView.vue"
      ),
  },
  {
    path: "/agb",
    name: "agb",
    component: AGBSectionView,
    //route level code-splitting
    //this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () =>
    //import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    component: DatenSchutzView,
    //route level code-splitting
    //this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () =>
    //import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
